
import { ObjectView } from 'react-object-view'
import { useState, useEffect } from "react";

const options = {
  hideDataTypes: true,
  hideObjectSize: false,
  previewOpacity: 1,
  displayEntriesMaxCount: 0,
  expandLevel: 3
}
const palette = {}

const data = {
  version: "1.0.8",
  name: "Saurabh Brar",
  email: "saurabhbraryo@gmail.com",
  github: "https://github.com/sholaybhature",
  linkedin: "https://www.linkedin.com/in/saurabh-brar/",
  "fancy-portfolio": "https://saurabhbrarportfolio.netlify.app/",
  about: "Self-taught developer with a background in mathematics having a keen interest in backend development and distributed systems.",
  skills: {
    languages: "Golang, TypeScript, Elixir",
    frameworks: "Gorilla, Phoenix, Ecto, Node.js, Prisma, NestJS",
    others: "Docker, Kubernetes, Postgres, RabbitMQ, ElasticSearch, Redis",
  },
  experience: {
    "Gamezop": {
      about: "Gaming/advertisement startup, backed by Series A funding, serving millions of users.",
      link: "https://business.gamezop.com/",
      role: "Backend Engineer",
      work: [
        "Designed and developed a large-scale opinion trading platform using Elixir and Golang as a core developer",
        "Implemented PostgreSQL sharding and worked extensively with RabbitMQ",
        "Improved the Elixir development and production CI/CD pipeline by 5x through efficient artifact caching and optimized build modules",
        "Developed and maintained multiple microservices in Golang, TypeScript and Elixir"
      ],
      experience: "March 2022 - Present"
    },
    "LetStream Ventures": {
      about: "A random web-development agency.",
      link: "https://www.theletstream.com/",
      role: "Backend Intern",
      work: [
        "Worked on multiple Django projects developing RESTful API’s using DRF",
        "Executed direct streaming uploads to AWS S3 using boto3, resulting in reduced server bandwidth and memory usage",
        "Enhanced functionality by seamlessly integrating multiple features into existing projects"
      ],
      experience: "October 2020 -  January 2021"
    }
  },
  projects: {
    "CodeLive": {
      github: "https://github.com/Nobitaaah/code-live",
      about: "An online code editor developed in Node.js that allows multiple users to write code in realtime. Supports syntax highlighting and auto-completion for multiple languages with no limit on participants.",
      work: [
        "Utilized real-time communication with socket.io using WebSocket protocol",
        "Formulated the back-end logic in Express and MongoDB",
        "Built the front end using React and Redux and used JWT for authentication"
      ]
    },
    "ProdAPI": {
      github: "https://github.com/sholaybhature/ProdAPI",
      about: "An online code editor developed in Node.js that allows multiple users to write code in realtime. Supports syntax highlighting and auto-completion for multiple languages with no limit on participants.",
      work: [
        "Utilized real-time communication with socket.io using WebSocket protocol",
        "Formulated the back-end logic in Express and MongoDB",
        "Built the front end using React and Redux and used JWT for authentication"
      ]
    },
    "Ramayana.viz": {
      github: "https://github.com/reedkihaddi/hindu_texts",
      about: "Inspired curiosity about the Ramayana through data visualization in Python, developed an easy to use interface for a summary of chapters in each Kanda.",
      work: [
        "Scrapped more than 20000 Sanskrit shlokas in Ramayana using BeautifulSoup4",
        "Carried out authentication and authorization using JWT access and refresh tokens",
        "Developed a website with back-end in Django to present the data in an elegant way"
      ]
    },
    others: {
      about: "Stuff that never saw the light of day.",
      work: {
        "chess.js-track-pieces": "https://github.com/sholaybhature/chess.js-track-pieces",
        "du-papers-fzf": "https://github.com/sholaybhature/Delhi-University-Papers",
        "ripchess": "https://github.com/sholaybhature/ripchess",
        "quant-models": "https://github.com/sholaybhature/Quant",
        "covid-19": "https://github.com/sholaybhature/COVID-19-Tracker",
        "lastcig": "https://github.com/sholaybhature/LastCig-Discord-Bot",
        "reddit-sentiment-analysis": "https://github.com/sholaybhature/reddit-sentiment-analysis",
        "cleanwiki": "https://github.com/sholaybhature/CleanWiki"

      }
    }
  },
  education: "BSc Mathematics",
  note: "Website is a work in progress, as is life. :)"
}

function App() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;
  let styles;
  if (!isMobile) {
    styles = {
      fontSize: 16,
      lineHeight: 1.8,
      tabWidth: 1
    }
  } else {
    styles = {
      fontSize: 12,
      lineHeight: 1.6,
      tabWidth: 1
    }
  }
  return (

    <div id='info' style={{
      width: "100%", backgroundColor: "rgb(34, 34, 34)", paddingTop: "2.5vh", paddingBottom: "2.5vh"
    }}>
      < div style={{
        wordBreak: "break-all", "width": "95%"
      }}>
        <ObjectView
          data={data}
          options={options}
          styles={styles}
          palette={palette}
        />
      </div >
    </div >

  );
}

export default App;
